import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Router from "vue-router";
import {
  Button,
  Tabs,
  TabPane,
  Card,
  Icon,
  Image,
  Carousel,
  CarouselItem,
} from "element-ui";
Vue.config.productionTip = false;
Vue.use(Button);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Card);
Vue.use(Icon);
Vue.use(Image);
Vue.use(Carousel);
Vue.use(CarouselItem);
// 解决路由地址跳转相同, 且没有捕获到错误，控制台始终会出现如图所示的警告
const originalPush = Router.prototype.push;
console.log("Router.prototype.push", Router.prototype.push);
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  window.pageYOffset = 0;
  next();
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
