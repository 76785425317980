/**
 * 引入所有路由
 *
 * 路由元(meta):
 * @property { String } title => 标题
 */

const constantRoutes = [
  {
    path: "/",
    redirect: "home",
  },
  {
    path: "*",
    redirect: "home",
  },
  {
    path: "/",
    component: () => import("@/layout/index.vue"),
    children: [
      {
        path: "/home",
        name: "HomePage",
        component: () => import("@/views/home/index.vue"),
        meta: {
          title: "首页",
        },
      },
      {
        path: "/dynamic",
        name: "Dynamic",
        component: () => import("@/views/dynamic/index.vue"),
        meta: {
          title: "公司动态",
        },
      },
      {
        path: "/dynamic/detail",
        name: "DynamicDetail",
        component: () => import("@/views/dynamic/page/detail/index.vue"),
        meta: {
          title: "衡阳市研究成果",
        },
      },
      {
        path: "/dynamic/secrecy",
        name: "secrecyDetail",
        component: () => import("@/views/dynamic/page/secrecy/index.vue"),
        meta: {
          title: "保密性声明",
        },
      },
      {
        path: "/about",
        name: "AboutUs",
        redirect: "/about/introduce",
        component: () => import("@/views/about/index.vue"),
        meta: {
          title: "关于理大",
        },
        children: [
          {
            path: "/about/introduce",
            name: "Introduce",
            component: () => import("@/views/about/page/introduce/index.vue"),
            meta: {
              title: "理大介绍",
            },
          },
          {
            path: "/about/media_reports",
            name: "MediaReports",
            component: () =>
              import("@/views/about/page/mediaReports/index.vue"),
            meta: {
              title: "媒体报道",
            },
          },
          {
            path: "/about/news",
            name: "News",
            component: () => import("@/views/about/page/news/index.vue"),
            meta: {
              title: "新闻资讯",
            },
          },
        ],
      },
      {
        path: "/contact",
        name: "ContactUs",
        component: () => import("@/views/contact/index.vue"),
        meta: {
          title: "联系我们",
        },
        // children: [{}],
      },
      {
        path: "/daily",
        name: "DailySituation",
        redirect: "/daily/traffic",
        component: () => import("@/views/daily/index.vue"),
        meta: {
          title: "核心业务",
        },
        children: [
          {
            path: "/daily/detection",
            name: "Detection",
            component: () => import("@/views/daily/page/detection/index.vue"),
            meta: {
              title: "试验检测",
            },
          },
          {
            path: "/daily/monitoring",
            name: "Monitoring",
            component: () => import("@/views/daily/page/monitoring/index.vue"),
            meta: {
              title: "安全监测",
            },
          },
          {
            path: "/daily/railway",
            name: "Railway",
            component: () => import("@/views/daily/page/railway/index.vue"),
            meta: {
              title: "铁路安全监测",
            },
          },
          {
            path: "/daily/maintain",
            name: "Maintain",
            component: () => import("@/views/daily/page/maintain/index.vue"),
            meta: {
              title: "养护咨询",
            },
          },
          {
            path: "/daily/house",
            name: "House",
            component: () => import("@/views/daily/page/house/index.vue"),
            meta: {
              title: "房屋安全监测",
            },
          },
          {
            path: "/daily/survey",
            name: "Survey",
            component: () => import("@/views/daily/page/survey/index.vue"),
            meta: {
              title: "勘察设计",
            },
          },
          {
            path: "/daily/traffic",
            name: "Traffic",
            component: () => import("@/views/daily/page/traffic/index.vue"),
            meta: {
              title: "数智交通",
            },
          },
          {
            path: "/daily/detection/detection_detail",
            name: "DetectionDetail",
            component: () =>
              import("@/views/daily/page/detection/page/detection_detail"),
            meta: {
              title: "试验检测",
            },
          },
          {
            path: "/daily/maintain/maintain_detail",
            name: "MaintainDetail",
            component: () =>
              import("@/views/daily/page/maintain/page/maintain_detail"),
            meta: {
              title: "养护咨询",
            },
          },
          {
            path: "/daily/house/house_detail",
            name: "HouseDetail",
            component: () =>
              import("@/views/daily/page/house/page/house_detail"),
            meta: {
              title: "房屋安全监测",
            },
          },
          {
            path: "/daily/railway/railwayDetail",
            name: "RailwayDetail",
            component: () =>
              import("@/views/daily/page/railway/page/railwayDetail/index.vue"),
            meta: {
              title: "铁路安全监测",
            },
          },
          {
            path: "/daily/survey/survey_detail",
            name: "SurveyDetail",
            component: () =>
              import("@/views/daily/page/survey/page/survey_detail"),
            meta: {
              title: "勘察设计",
            },
          },
          {
            path: "/daily/traffic/traffic_detail",
            name: "TrafficDetail",
            component: () =>
              import("@/views/daily/page/traffic/page/traffic_detail"),
            meta: {
              title: "数智交通",
            },
          },
        ],
      },
      {
        path: "/product",
        name: "Product",
        component: () => import("@/views/product/index.vue"),
        meta: {
          title: "服务体系",
        },
      },
    ],
  },
  {
    path: "/redirect",
    component: () => import("@/layout/index.vue"),
    children: [
      {
        path: "/redirect/:path(.*)",
        component: () => import("@/views/redirect/index"),
      },
    ],
  },
];

export default constantRoutes;
